import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Web Design",
      desc: "Using your choice of web technologies, I can build you a professional website that will stand out from the competition, leaving a world class impression on your clients.",
      icon: "fas fa-desktop",
    },
    {
      name: "App Development",
      desc: "Whether you need a web app or a mobile app, my years of experience allow me to design and deliver award-winning solutions that will exceed your expectations.",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: "SIP Telephony",
      desc: "I have designed and built carrier-grade telephony platforms using open source solutions such as Asterisk, FreeSWITCH and Kamailio. I speak fluent SIP.",
      icon: "fas fa-phone",
    },
    {
      name: "Business Analysis",
      desc: "I can help with the technical transformation of your business, and put the power of your data back in your hands, leaving you to make informed, efficient decisions.",
      icon: "fas fa-chart-area",
    },
    {
      name: "Artificial Intelligence",
      desc: "I have expert knowledge and understanding in a number of AI disciplines, specialising in Machine Learning, Computer Vision and Natural Language Processing.",
      icon: "fas fa-robot",
    },
    {
      name: "3D Art",
      desc: "Using a combination of Fusion 360, Blender and various Adobe tools, I create highly realistic hard surface 3D models, either for 3D printing or rendering.",
      icon: "fas fa-palette",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
