import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2014 - 2016",
      title: "M.Sc. Artificial Intelligence",
      place: "Stockholm University",
      desc: "",
    },
    {
      yearRange: "2000 - 2004",
      title: "B.Sc. (Hons) Computer Science (Artificial Intelligence)",
      place: "University of Liverpool",
      desc: "",
    },
    {
      yearRange: "1997 - 1999",
      title: "HND Computer Engineering",
      place: "Glasgow College of Nautical Studies",
      desc: "",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2020 - Present",
      title: "CEO",
      place: "Blue Orange Industries",
      desc: "London, United Kingdom",
    },
    {
      yearRange: "2019 - Present",
      title: "Senior AI Consultant",
      place: "SpaceX",
      desc: "Los Angeles, CA",
    },
    {
      yearRange: "2018 - Present",
      title: "Senior AI Consultant",
      place: "Tesla",
      desc: "San Fracisco, CA",
    },
    {
      yearRange: "2017 - Present",
      title: "Founder & CTO",
      place: "Square Brackets",
      desc: "San Francisco, CA",
    },
    {
      yearRange: "2016 - Present",
      title: "Head of Development",
      place: "Chess ICT",
      desc: "Alderley Edge, United Kingdom",
    },
    {
      yearRange: "2010 - 2016",
      title: "Senior Development Consultant",
      place: "Pinnacle Telecom Group Plc",
      desc: "Northampton, United Kingdom",
    },
    {
      yearRange: "2004 - 2010",
      title: "Co-founder & Technical Director",
      place: "Solwise Telephony",
      desc: "Hinckley, United Kingdom",
    },
 ];

  const skills = [
    {
      name: "Computer Vision",
      percent: 100,
    },
    {
      name: "Machine Learning",
      percent: 100,
    },
    {
      name: "HTML / CSS",
      percent: 100,
    },
    {
      name: "PHP",
      percent: 100,
    },
    {
      name: "C / C++",
      percent: 95,
    },
    {
      name: "Python",
      percent: 95,
    },
    {
      name: "SQL / NoSQL",
      percent: 90,
    },
    {
      name: "Node / JS",
      percent: 90,
    },
    {
      name: "Asterisk",
      percent: 95,
    },
    {
      name: "FreeSWITCH",
      percent: 90,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of my resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Resume;
