import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  const faqsData = [
    {
      question: "How can I help you?",
      answer:
        "I have a very unique background and over two decades of experience spanning a number of industries from telecoms to automative and aeronautics. I understand software and the benefits it can bring to businesses. Coupled with my expertise in artificial intelligence, automation and business transformation, there are very few software challenges that I'm unable to overcome. If you have a requirement for software, there's a very good chance I can design and build the perfect solution.",
    },
    {
      question: "Are you available now?",
      answer:
        "I always have at least one project running alongside my day-to-day business. However, I can usually provide accurate delivery timelines based on my current and future commitments, and on your requirements. Get in touch, and once I understand your needs, I can let you know when I'm available.",
    },
    {
      question: "What are your fees?",
      answer:
        "As a general rule of thumb, I price my work to suit the project. However, my day rate for smaller engagements is £950 GBP.",
    },
    {
      question: "Are you available to travel?",
      answer:
        "Depending on the requirements of the project, I can be available for national and international travel. I always put my the needs of my clients first and do what needs to be done to deliver a world class solution.",
    },
  ];

  return (
    <section id="faq" className="section bg-light">
      <div className="container">
        <div className="row gy-5">
          <div className="col-lg-6 order-1 order-lg-0 wow fadeInUp">
            {/* Heading */}
            <p className="text-center text-lg-start mb-2">
              <span className="bg-primary text-dark px-2">FAQ</span>
            </p>
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Have any questions?
            </h2>
            {/* Heading end*/}

            <Accordion flush defaultActiveKey="0">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          <div
            className="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn"
            data-wow-delay="1s"
          >
            {" "}
            <img
              className="img-fluid"
              src="images/faq.png"
              title="FAQ"
              alt="faq"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
