import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">About Me</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A little bit about me
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <p className="text-5">
            I'm a highly competent software engineer that takes an entrepreneurial approach to creating innovative software solutions.
            I have worked with many prestigious clients such as Jaguar Land Rover, BBC, Tesla and SpaceX. I currently run my own business but enjoy new
            and exciting consultancy opportunities.
            </p>
            <p className="text-5">
            In my spare time I'm a pilot, dabble with guitar and piano, love playing computer games and enjoy film and 3D modelling.  
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  23
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Years of <span className="fw-700">Experience</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-4 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-dark fw-600 mb-0">David Mitchell</p>
          </div>
          <div className="col-6 col-lg-4 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href="mailto:david@bettercalldavid.co.uk">
                david@bettercalldavid.co.uk
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">From:</p>
            <p className="text-4 text-dark fw-600 mb-0">Surrey, United Kingdom</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
